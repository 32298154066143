import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import { Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class Logout extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
       await this.props.store.logout();
       this.props.history.replace("/");
    }

    render()
    {
        const main = <div className="flex flex-row pt-20 items-center justify-center"><Spinner/></div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}