import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Menu, MenuItem, MenuDivider, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import WxLoginButton from '../component/WxLoginButton'; 

@withRouter
@inject("store")
@observer
export default class UserMenu extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    render()
    {
        const theMenu = (
            <Menu className="border">
                <MenuItem icon="list" text="试用列表" onClick={()=>this.props.history.push("/install/list")} />
                <MenuItem icon="applications" text="我的应用" onClick={()=>this.props.history.push("/myinstances")} />
                <MenuDivider />
                <MenuItem icon="person" text="我的" onClick={()=>this.props.history.push("/profile")} />
                <MenuItem icon="dollar" text="充值" onClick={()=>this.props.history.push("/recharge")} />
                <MenuDivider />
                <MenuItem icon="log-out" text="退出" onClick={()=>this.props.history.push("/logout")} />
            </Menu>
        );
        
        const { staticContext, ...others } = this.props;
        return <div {...others} >
            { this.props.store.user ? <Popover2 autoFocus={false} content={theMenu} position={Position.BOTTOM_RIGHT} 
            //interactionKind="hover"
            >
                <Button rightIcon="caret-down" minimal={true} />
            </Popover2> :  <Link to="/login">登入</Link> }
            
        </div>;
    }
}