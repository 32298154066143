import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Index from './screen/Index';
import ImportData from './screen/ImportData'; 
import LoginBack from './screen/LoginBack'; 
import Recharge from './screen/Recharge'; 
import Logout from './screen/Logout'; 
import Profile from './screen/Profile'; 
import Login from './screen/Login'; 
import AfterLogin from './screen/AfterLogin';
import AppAdminList from './screen/AppAdminList'; 
import AppAdminCreate from './screen/AppAdminCreate';
import AppAdminModify from './screen/AppAdminModify'; 
import AppList from './screen/AppList'; 
import MyInstance from './screen/MyInstance'; 
import AutoLogin from './screen/AutoLogin'; 


class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
        <Route path="/login/auto/:token" component={AutoLogin} />
          <Route path="/myinstances" component={MyInstance} />
          <Route path="/appadmin/modify/:id" component={AppAdminModify} />
          <Route path="/appadmin/create" component={AppAdminCreate} />
          <Route path="/install/list" component={AppList} />
          <Route path="/appadmin/list" component={AppAdminList} />
          <Route path="/after/login" component={AfterLogin} />
          <Route path="/profile" component={Profile} />
          <Route path="/recharge" component={Recharge} />
          <Route path="/logout" component={Logout} />
          <Route path="/logback" component={LoginBack} />
          <Route path="/login" component={Login} />
          <Route path="/index" component={Index} />
          <Route path="/" component={ImportData} />
        </Switch>
      </Router>  
    );
  }
}

export default App;