import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class AppItem extends Component
{
    state = {"loading":false};
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    async launch( app_id, hours )
    {
        if( !window.confirm("你确定要开始试用吗？将为您安装全新的环境，并预先扣除相应费用。如果创建失败会返还费用，请到「我的」页面刷新后查看") ) return false;
        this.setState({"loading":true});
        const ret = await this.props.store.create_instance( app_id, hours );
        this.setState({"loading":false});
        if( ret && ret.myinstance )
        {
            await this.props.store.refresh_user();
            toast("请求已发起，请进入实例列表，稍等几分钟后，点击「刷新应用状态」按钮");
            this.props.history.push("/myinstances");
        }
        else
        {
            console.log( ret );
            if( ret.msg ) toast( ret.msg );
        }
        
    }   
    
    render()
    {
        const item = this.props.data;
        if( !item ) return null;

        return <div className="">
            <div className="line border-t border-dotted pt-1 mt-1"></div>
            <div className="py-3 flex flex-col md:flex-row  md:justify-between items-center rounded-lg  md:hover:bg-gray-50 ">
            <div className="left flex flex-row items-center mb-2 md:mb-0">
                <span className="bg-yellow-300 p-1 px-2 rounded mr-2">{item.id}</span>
                <div className="desp">
                {item.link ? <a href={item.link} target="_blank">{item.name}</a> : item.name }    
                    </div>
            </div>
            <div className="right md:pr-0 rounded bg-gray-50 md:bg-transparent md:mt-0 md:w-auto md:flex justify-center">

            {this.props.type && this.props.type == 'install' ? ( !this.state.loading ? <Button large={true} className="ml-2" onClick={()=>this.launch(item.id,this.props.store.form_instance_hours)}>花费{this.props.store.form_instance_hours*this.props.store.form_instance_price*(item.price_times||1)}金币(¥{(this.props.store.form_instance_hours*this.props.store.form_instance_price*(item.price_times||1)/100).toFixed(2)})试用{this.props.store.form_instance_hours}小时</Button> : <Spinner size="12"/> )  : <Button large={true} className="ml-2" onClick={()=>this.props.history.push("/appadmin/modify/"+item.id)}>修改</Button>  }    
              
               
                
            </div>
            
        </div>
        </div>;
    }
}