import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 


@withRouter
@inject("store")
@observer
export default class Profile extends Component
{
    state = {"user":false};
    
    async componentDidMount()
    {
        const ret = await this.props.store.get_user_profile();
        if( ret && ret.user )
        {
            this.setState({"user":ret.user});
            this.props.store.set_user(ret.user);
        }
    }

    render()
    {
        const user = this.state.user;
        if( !user ) return null;

        const main = <div className="p-2 flex flex-col profile-page">
            <div className="profile">
                <div className="avatar">
                    <img src={user.avatar_url} alt=""/>
                    <div className="explain">{user.name}</div>
                </div>
            </div>
            <div className="detail">
                <div className="row">
                    <div className="left">
                        Level
                    </div>
                    <div className="right">
                        {user.level}
                    </div>
                </div>
                <div className="row">
                    <div className="left">
                        Coin
                    </div>
                    <div className="right">
                        {user.coin}
                    </div>
                </div>
            </div>
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}