import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import InstanceItem from '../component/InstanceItem'; 
import { Button, Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class MyInstance extends Component
{
    state = {"instances":false};
    
    async componentDidMount()
    {
        await this.load_instances();
        this.timeout = window.setTimeout(() => {
            this.check();
        }, 2000);
        this.interval = window.setInterval(() => {
            this.check();
        }, 1000*60*2);
        
    }

    componentWillUnmount()
    {
        window.clearTimeout( this.timeout );
        window.clearInterval( this.interval );
    }

    async load_instances()
    {
        const ret = await this.props.store.list_my_instances();
        if( ret && ret.instances )
        {
            for( let i of ret.instances )
            {
                this.props.store.instance_to_check.set( i.instance_id, i );
            }

            this.setState({"instances":ret.instances});
        }
    }

    async check( reload = true )
    {
        for (let [key, value] of this.props.store.instance_to_check.entries()) {
            //console.log(key , JSON.stringify(value));
            if( value.disabled != 1 )
            {
                const ret = await this.props.store.detail_instance( key );
                console.log( ret );
            }
        }

        await this.load_instances();
    }

    render()
    {
        const main = <div className="py-5 px-5 md:px-0">
        {!this.state.instances ? <div className="p-5 flex justify-center">
            <Spinner/>
        </div> : (
            this.state.instances.length < 1  ? <div>还没有已经创建的实例</div> : <div>
                <div className="mb-5"><Button large={true} icon="refresh" onClick={()=>this.check()}>刷新应用状态</Button></div>
                {this.state.instances && this.state.instances.map( item => <InstanceItem key={item.id} data={item} /> )} </div>
            )

            }

            {this.state.apps?.length > 1 &&
            <div className="text-center text-gray-300 p-5 mt-8">
                - 没有更多了 -
            </div>
            }    
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}