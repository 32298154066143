import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import WxLoginButton from '../component/WxLoginButton'; 
import { Button } from '@blueprintjs/core';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class ImportData extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const main = <div className="py-5 px-5 md:px-0">
        <div className="img-box p-8 mt-5 bg-gray-100 rounded-lg flex items-center flex-col">
            <img src="/cloud-chan.png" width="200" alt="logo" />
            <span className="mt-5 text-lg">Cloud酱<sup>beta</sup> - 云上应用一键装</span>
            <div className="text-gray-400 mt-2">看板娘由charat.me生成</div>
            <div className="mt-5"><Button large={true} onClick={()=>this.props.history.push('/install/list')}>查看可试用的云应用</Button></div>
            
            
        </div>
        <div className="hero pt-5">
            <div className="text-lg">
                Cloud酱是云应用的一键安装工具，专注于提供新应用的试用服务，无需花上一两个小时读文档倒腾命令行，只需一次点击即可创建一个最长6个小时的试用环境。当你试用满意后，可以自行购买服务器（<a href="https://plink.ftqq.com/1" target="_blank">优惠链接</a>）粘贴一键安装命令安装。
            </div>
            <div className="text-gray-400 mt-2 leading-loose">由于在大陆网络架设服务器需要进行备案，且架设者需严格控制内容安全，我们暂不提供长期的云应用托管服务。您可以自行开通云平台账号，购买服务器并以自己的身份进行备案后长期使用。我们会为大家提供<a href="https://01.ftqq.com/category/one-line-install/" target="_blank">一键安装教学</a>。<Button minimal={true} onClick={()=>toast('喵')}>{"ฅ>_<ฅ"}</Button></div>
            
        </div>
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}