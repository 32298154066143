import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import AuthedView from '../component/AuthedView'; 
import TextLine from '../component/TextLine';
import { is_in_wechat, toast } from '../util/Function';
import BuyButton from '../component/BuyButton'; 

@withRouter
@inject("store")
@observer
export default class Recharge extends Component
{
    state = {"jsinfo":false,"loading":false};
    
    componentDidMount()
    {
        // this.btn = window.document.querySelector("#recharge-btn");
        // console.log( this.btn );

        if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
        }
        function onBridgeReady() {
            // WeixinJSBridge.call('hideOptionMenu');
            //window.setTimeout( launch, 1000 );
        } 
    }

    launch()
    {
        let that = this;
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
                // 以下6个支付参数由本接口获取
                // **************************
                "appId": this.state.jsinfo.appId,
                "timeStamp": this.state.jsinfo.timestamp,
                "nonceStr": this.state.jsinfo.nonceStr,
                "package": this.state.jsinfo.package,
                "signType": this.state.jsinfo.signType,
                "paySign": this.state.jsinfo.paySign,
                // **************************
            },
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    toast("支付数据处理中...");
                    that.props.history.push("/profile");
                    // WeixinJSBridge.call('closeWindow');
                }
            }
        );

        console.log("launch")

    }
    

    async recharge()
    {
        // this.btn.readOnly = true;
        const ret = await this.props.store.preorder( this.props.store.recharge_input_coin , window.location.origin + '/profile' ); 
        if( ret && ret.prepay_id && ret.order_id )
        {
            const red_url = this.props.store.apibase + 'global/pay/payit?id='+ ret.prepay_id + '&order_id='+ret.order_id ;

            window.location = red_url;
        }
        // this.btn.readOnly = false;
        
        // old version
        // const jsinfo = await this.props.store.preorder( this.props.store.recharge_input_coin );
        // if( jsinfo )
        // {
        //     if( jsinfo.result )
        //     {
        //         this.setState({"jsinfo":jsinfo.result})
        //         window.setTimeout( ()=>this.launch(), 600 );
        //     }

        //     if( jsinfo.error )
        //     {
        //         toast( jsinfo.error.err_code_des );
        //     }
        // }
    }

    render()
    {
        const main = <div className="p-2 recharge-page ft-form px-5 md:px-0">
            <div className="title text-xl my-2">充值金币</div>
            <div className="detail text-sm text-gray-500">1RMB = 100枚金币 · 不可反向兑换 · 不可退款</div>
            <div className="price">
            ¥{(this.props.store.recharge_input_coin/100).toFixed(2)}
            </div>
            <TextLine field="recharge_input_coin" type="number" placeholder={"请输入要购买的"+this.props.store.coin_name+"数量"}/>
            <BuyButton large={true} onClick={()=>this.recharge()} forward="recharge-btn">充值</BuyButton> 
        </div>;
        return <AuthedView><SoloView title={this.props.store.appname} main={main} /></AuthedView>;
    }
}