import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Callout, AnchorButton, Tooltip } from '@blueprintjs/core';
import { toast } from '../util/Function';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import dayjs from 'dayjs';
import * as Showdown from "showdown";

var relativeTime = require('dayjs/plugin/relativeTime');

var thresholds = [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy', d: 'year' }
];

dayjs.extend(relativeTime, {thresholds}); 

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});


@withRouter
@inject("store")
@observer
export default class InstanceItem extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    async destroy( instance_id )
    {
        if( !window.confirm("确定要提前销毁云服务器吗？销毁后不能恢复，如果剩余时间较多，会返还一定的金币，可到「我的」页面查询") ) return false;

        const ret = await this.props.store.destroy_instance(instance_id);

        toast("销毁请求已经发起，请点击页面上的刷新按钮，获取最新的实例状态");
    }
    
    render()
    {
        const item = this.props.data;
        if( !item ) return null;
        if( item.disabled == 1 ) return null;
        const html = converter.makeHtml(item.after_install.replace('{{$ip}}',item.public_ip));

        return <div className="mt-5 ">
            <div className="line border-t border-dotted mb-5"></div>
            <div className="flex flex-col md:flex-row  md:justify-between mb-2 items-center rounded-lg  ">
            <div className="left flex flex-1 flex-col">
                <div className="flex flex-col md:flex-row items-center mb-2">
                    <span className="bg-yellow-300 p-1 px-2 rounded mr-2 md:mb-0 mb-2">{item.name}</span>
                    
                    <Tooltip content={item.stop_time} className="md:mb-0 mb-2">
                    <span className="bg-red-700 text-white p-1 px-2 rounded mr-2  cursor-pointer">
                    {dayjs(item.stop_time).fromNow()}
                    </span>
                    </Tooltip>
                    

                    <span className="bg-gray-100 md:mb-0 mb-2 p-1 px-2 rounded mr-2">
                    <code>{item.public_ip||"-"}</code>    
                    </span>
                    <span className="bg-gray-100 md:mb-0 mb-2 p-1 px-2 rounded mr-2">
                    {item.instance_id}  
                    </span>
                    
                </div>
            </div>
            <div className="right md:pr-0 rounded bg-gray-50 md:bg-transparent md:mt-0 md:w-auto md:flex justify-center ">
                {item.public_ip && <AnchorButton icon="application" className="mr-2 mb-2" href={"http://"+item.public_ip+(item.entrance||"")} target="_blank">访问</AnchorButton>}

                <AnchorButton icon="shop" className="mr-2 mb-2" href="https://01.ftqq.com/2021/08/31/how-to-buy-vps/" target="_blank">自购服务器</AnchorButton>

                <CopyToClipboard text={'curl -fsS "https://openapi.ftqq.com/clickcloud/init_command/'+ item.app_id +'?password='+item.password+'" | sudo bash'} onCopy={()=>{toast("已经复制到剪贴板")}}><Button icon="duplicate" className="mr-2 mb-2" >一键安装命令</Button></CopyToClipboard>

                <Button icon="build" className="mr-2 mb-2" onClick={()=>this.destroy(item.instance_id)}>销毁</Button>


            </div>
            </div>
            <div className="action">
                <Callout>
                    {item.public_ip && <>
                    
                    <div dangerouslySetInnerHTML={{__html:html}} />

                    <div className="my-2">🎈 &nbsp;云应用需要时间初始化和配置，分配公网IP后，请等待2~5分钟再访问应用</div>
                    <div className="my-2">🖥  &nbsp;高阶用户亦可 <CopyToClipboard text={'ssh ubuntu@'+item.public_ip} onCopy={()=>{toast("已经复制到剪贴板")}}><code className="bg-yellow-300 px-2 py-1 rounded cursor-pointer mx-1">ssh ubuntu@{item.public_ip}</code></CopyToClipboard> 用密码 
                    <CopyToClipboard text={item.password} onCopy={()=>{toast("已经复制到剪贴板")}}><code className="bg-yellow-300 px-2 py-1 mx-1 rounded cursor-pointer">{item.password}</code></CopyToClipboard>
                    
                    登入服务器</div>
                    
                    </>}
                </Callout>
            </div>
            </div>;
    }
}