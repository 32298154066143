import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import TextLine from '../component/TextLine';
import SubmitLine from '../component/SubmitLine';
import CodeLine from '../component/CodeLine'; 
import { Callout } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class AppAdminModify extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
        const id = parseInt(this.props.match.params.id);
        this.id = id;
        if( id > 0 )
        {
            const ret = await this.props.store.detail_app( id );
            if( ret && ret.app )
            {
                this.props.store.set_app( ret.app );
            }
        }
    }

    async save()
    {
        const ret = await this.props.store.update_app(this.id);
        if( ret && ret.app ) this.props.history.push("/appadmin/list");
    }

    render()
    {
        if( this.props.store.user.level < 9 ) return <Callout className="p-10">只有管理员有权限管理应用</Callout>;
        
        const main = <div className="py-5 px-5 md:px-0">
        <div className="ft-form">
            <div className="title text-xl mb-5">创建一键安装应用</div>
            <TextLine field="form_app_name" className="mt-5" label="应用名称"/>
            <TextLine field="form_app_link" className="mt-5" label="应用介绍链接"/>
            <TextLine field="form_app_entrance" className="mt-5" label="入口页面"/>
            <CodeLine language="yaml" field="form_app_docker_compose" type="textarea" className="mt-2" label="Docker-compose文件" placeholder="在这里输入docker-compose内容，支持{{$password}}变量"/>
            <CodeLine language="bash" field="form_app_custom_command" type="textarea" className="mt-2" label="自定义命令" placeholder="在这里输入命令内容，支持{{$password}}变量，填写后将忽略docker-compose文件内容"/>
            <TextLine field="form_app_after_install" className="mt-5" label="安装完成后的提示信息" placeholder="支持{{$password}}变量" type="textarea"/>
            <SubmitLine onSubmit={()=>this.save()}/>
        </div>
        </div>;

        return <SoloView title={this.props.store.appname} main={main} />;
    }
}