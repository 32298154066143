import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import { Spinner } from '@blueprintjs/core';

@withRouter
@inject("store")
@observer
export default class AutoLogin extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
        const token = this.props.match.params.token;
        const ret = await this.props.store.check_user_info( token ); 
        console.log(ret);
        if( ret && ret.user )
        {
            this.props.store.set_user( ret.user );
            const params = new URLSearchParams(this.props.location.search);
            let redirect = params.get( 'redirect' );
            if( !redirect ) redirect = '/recharge';
            this.props.history.push( redirect );
        }
    }

    render()
    {
        const main = <div className="py-5 px-5 md:px-0">
            <div className="p-5 flex justify-center">
            <Spinner/>
        </div>
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}