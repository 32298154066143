import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { is_in_wechat, toast } from '../util/Function';
import sr from 'simple-random';

@withRouter
@inject("store")
@observer
export default class AuthedView extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        if( !this.props.store.user || !this.props.store.token )
        {
            
        
            
            window.setTimeout( async()=> {
                this.props.store.session = sr({"length":32});
                const ret = await this.props.store.get_wechat_login_url();
                if( ret && ret.url )
                {
                    if( !is_in_wechat() )
                    {
                        window.location = '/login';
                    }
                    else
                    {
                        window.location = ret.url;
                    }
                }
            }, 100 );
        } 
       
    }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const view = this.props.store.user && this.props.store.token ? this.props.children : <div className="p-5 flex justify-center"><Spinner/></div>; 

        // console.log( "view" , view );
        
        return view;
    }
}