import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, NavLink } from 'react-router-dom';

@withRouter
@inject("store")
@observer
export default class TopNavMenu extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        // console.log( JSON.stringify(this.props.store.user) );
        const { staticContext , ...other } = this.props;
        return <div {...other}>
            <div className="flex flex-row items-center">
                {this.props.store.menu && <> <svg className="w-3 h-3 mr-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg> </>}
                {this.props.store.menu && this.props.store.menu.map( (item) => {
                    let show = true;
                    if( item.member_only )
                    {
                        if( !this.props.store.token ) show = false; 
                    }
                    if( item.admin_only )
                    {
                        if( this.props.store.user?.level < 9 ) show = false;
                    }

                    if(  show ) return <NavLink className="nav-menu text-black text-lg hover:text-gray-500 hover:no-underline " key={item.id} to={item.link}>{item.text}</NavLink>;
                } ) }
            </div>
            
        </div>;
    }
}