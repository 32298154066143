import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import { AnchorButton, Spinner } from '@blueprintjs/core';
import AppItem from '../component/AppItem'; 
import DropLine from '../component/DropLine';
import TextLine from '../component/TextLine';
import { toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class AppList extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    state = {"apps":false};
    
    async componentDidMount()
    {
        const ret = await this.props.store.list_public_app();
        if( ret && ret.apps )
        {
            this.setState({"apps":ret.apps});
        }
    }

    render()
    {
        const hours = [
            {"id":1,"value":1,"label":"1小时"},
            {"id":2,"value":2,"label":"2小时"},
            {"id":3,"value":3,"label":"3小时"},
            {"id":4,"value":4,"label":"4小时"},
            {"id":5,"value":5,"label":"5小时"},
            {"id":6,"value":6,"label":"6小时"},
        ];

        const apps = this.state.apps ? (
            this.props.store.form_search_keyword ? 
            this.state.apps.filter( item=> item.name.toLowerCase().indexOf( this.props.store.form_search_keyword ) > 0 ) : this.state.apps ): false;

        const main = <div className="py-5 px-5 md:px-0">
        <DropLine label="请选择要试用的小时数" field="form_instance_hours" options={hours} />
        
        <TextLine field="form_search_keyword" className="mt-2 mb-2" placeholder="输入关键字"/>
        

        {!apps ? <div className="p-5 flex justify-center">
            <Spinner/>
        </div> : (
            apps.length < 1  ? <div>还没有可以安装的应用</div> : apps && apps.map( item => <AppItem type="install" key={item.id} data={item} /> )
            )

            }

            {apps?.length > 1 &&
                <div className="text-center text-gray-300 p-5 mt-8">
                    - 没有更多了 -
                </div>
            }
        
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}