import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import { Spinner } from '@blueprintjs/core';
import { is_in_wechat, toast } from '../util/Function';

@withRouter
@inject("store")
@observer
export default class LoginBack extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    async componentDidMount()
    {
        
        const params = new URLSearchParams(this.props.location.search);
        const code = params.get( 'code' );
        const session = params.get( 'session' );

        const data = await this.props.store.get_token_by_code( code, session );
        if( data?.token )
        {
            this.props.store.set_user( data.user );
        }

        if( is_in_wechat() )
        {
            this.props.history.push("/after/login");
        }
        else
        {
            this.props.history.push("/home");
        }
    }

    render()
    {
        const main = <div className="flex flex-row pt-20 items-center justify-center"><Spinner/></div>;
        return main;
    }
}