import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { is_in_wechat, toast } from '../util/Function';
import { Overlay } from '@blueprintjs/core';
import QRImage from '../component/QRImage';
import sr from 'simple-random';

@withRouter
@inject("store")
@observer
export default class WxLoginButton extends Component
{
    state = {"qrcode_url":false};
    
    async go()
    {
        this.props.store.session = sr({"length":32});
        const ret = await this.props.store.get_wechat_login_url();
        if( ret && ret.url )
        {
            if( !is_in_wechat() )
            {
                this.setState({"qrcode_url":ret.url});
                console.log( ret.url );
                // window.location = ret.url;
                // show overlay qrcode
            }
            else
            {
                window.location = ret.url;
            }
        }
    }

    async check()
    {
        // get_user_info
        const token = this.props.store.session;
        const ret = await this.props.store.check_user_info(token);
        if( ret && ret.code > 0 )
        {
            toast("请扫码后再点击此按钮");
        }
        else
        {
            if( ret && ret.user )
            {
                this.props.store.set_user( ret.user );
                this.setState({"qrcode_url":false});
                if( is_in_wechat() )
                {
                    this.props.history.push("/after/login");
                }
                else
                {
                    this.props.history.push("/home");
                }
            }
        }
    }
    
    render()
    {
        const { staticContext, onClick , ...others } = this.props;
        return <>
        <Button text="微信登入" onClick={()=>this.go()} {...others}/>
        {this.state.qrcode_url && <Overlay isOpen={this.state.qrcode_url} canOutsideClickClose={false}  onClose={()=>this.setState({"qrcode_url":false})}>
            <div className="center-box bg-white p-10 rounded">
                <QRImage value={this.state.qrcode_url} />
                <div className="mt-2"><Button large={true} onClick={()=>this.check()}>微信扫码后点此继续</Button></div>
            </div>
        </Overlay>}
        </>;
    }
}