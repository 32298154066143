import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 
import { AnchorButton, Spinner } from '@blueprintjs/core';
import AppItem from '../component/AppItem'; 

@withRouter
@inject("store")
@observer
export default class AppAdminList extends Component
{
    state = {"apps":false};
    
    async componentDidMount()
    {
        const ret = await this.props.store.list_app();
        if( ret && ret.apps )
        {
            this.setState({"apps":ret.apps});
        }
    }

    render()
    {
        const main = <div className="py-5 px-5 md:px-0">
        <div className="mb-5"><AnchorButton href="/appadmin/create" large={true}>创建一键安装应用</AnchorButton></div>
        

        {!this.state.apps ? <div className="p-5 flex justify-center">
            <Spinner/>
        </div> : (
            this.state.apps.length < 1  ? <div>还没有可以安装的应用</div> : this.state.apps && this.state.apps.map( item => <AppItem key={item.id} data={item} /> )
            )

            }

            {this.state.apps?.length > 1 &&
                <div className="text-center text-gray-300 p-5 mt-8">
                    - 没有更多了 -
                </div>
            }
        
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}