import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { is_in_wechat, toast } from '../util/Function';
import { Overlay } from '@blueprintjs/core';
import QRImage from '../component/QRImage';

@withRouter
@inject("store")
@observer
export default class BuyButton extends Component
{
    state = {"qrcode_url":false};
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    go()
    {
        if( !is_in_wechat() )
        {
            const url = window.location.origin + '/login/auto/'+this.props.store.token+'?redirect='+encodeURIComponent(window.location.pathname);
            // alert( url );
            this.setState({"qrcode_url":url});
        }
        else
        {
            if( this.props.onClick ) this.props.onClick();
        }
            
    }
    
    render()
    {
        const { staticContext, onClick , ...others } = this.props;
        return <>
        <Button onClick={()=>this.go()} {...others}/>
        {this.state.qrcode_url && <Overlay isOpen={this.state.qrcode_url} onClose={()=>this.setState({"qrcode_url":false})}>
            <div className="center-box bg-white p-10 rounded">
                <QRImage value={this.state.qrcode_url} />
                <div className="mt-2">请用微信扫码支付</div>
            </div>
        </Overlay>}
        </>;
    }
}