import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import SoloView from '../component/SoloView'; 


@withRouter
@inject("store")
@observer
export default class AfterLogin extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const main = <div className="py-5 px-5 md:px-0 text-xl">
            移动版登入成功，可回到电脑点击按钮继续登入Web版，或点击右上角菜单进行「充值」等操作。
        </div>;
        return <SoloView title={this.props.store.appname} main={main} />;
    }
}