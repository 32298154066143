import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import UserSpan from '../component/UserSpan'; 
import UserMenu from '../component/UserMenu'; 
import TopNavMenu from '../component/TopNavMenu'; 

@withRouter
@inject("store")
@observer
export default class Header extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    // componentDidUpdate(prevProps)
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
           
    //     }
    // }
    
    render()
    {
        const user = this.props.store.user;
        // console.log( "user" , user );

        return <div className={(this.props.className||"")+" header"}>
            <div className="container mx-auto">
                <div className="left flex flex-row items-center">
                    <Link to="/">{this.props.store.appname}</Link> 
                    <TopNavMenu className="ml-5 hidden md:block"/>
                </div>
                <div className="right">
                    {user && <UserSpan data={user}/>}
                    <UserMenu className="ml-2"/>
                </div>
            </div>
           
        </div>;
    }
}