import { observable, action } from "mobx";
import { toast, is_in_wechat } from '../util/Function';
import FtApi from '../util/FtApi';

const APP_VAR_PREFIX = 'FT_CLICKCLOUD_VAR_';

class AppState
{
    @observable appname = "Cloud酱";
    @observable apibase = process.env.REACT_APP_API_BASE;
    @observable session = ""; 
    @observable token = ""; 
    @observable user = ""; 
    
    @observable menu = [
        {"id":8001,"text":"首页","link":"/home","member_only":false},
        {"id":8010,"text":"试用列表","link":"/install/list","member_only":false},
        {"id":8002,"text":"我的应用","link":"/myinstances","member_only":true},
        {"id":8006,"text":"管理应用","link":"/appadmin/list","member_only":true,"admin_only":true},
        // {"text":"","link":"","member_only":""},
        // {"text":"","link":"","member_only":""},
    ];

    ftopenapp_id = process.env.REACT_APP_FT_APPID; 
    to_save_vars = ['user','token'];
    
    constructor()
    {
        this.api = new FtApi( this );
        this.load_vars();
    }

    load_vars()
    {
        this.to_save_vars.forEach( v =>
        {
            const ret = window.localStorage.getItem(APP_VAR_PREFIX+v);
            if( ret ) this[v] = JSON.parse( ret );
        });
    }

    save_vars( field = null )
    {
        const vars_array = field === null ? this.to_save_vars : [field];
        vars_array.forEach( v =>
        {
            window.localStorage.setItem(APP_VAR_PREFIX+v, JSON.stringify( this[v] ));
        });
    }

    async get_wechat_login_url( appid = this.ftopenapp_id , session = this.session )
    {
        const data = await this.api.get( "global/user/get_wx_sns_login_url?id="+appid+'&session='+session );
        
        return data;
    }

    async get_token_by_code( code, session='' )
    {
        const data = await this.api.post( "global/user/get_token_by_wx_code" , { code, session } );
        
        return data;
    }

    async preorder( amount_cent, redirect )
    {
        const data = await this.api.post( "global/pay/order" , {amount_cent,redirect} );
        return data;
    }

    @action logout()
    {
        this.user = null;
        this.token = null;
        window.localStorage.clear();
    }

    @action
    set_user( user )
    {
        this.user = user;
        this.token = user.session_id;
        this.save_vars();
    }

    async check_user_info( token = null )
    {
        if( token )
        {
            const data = await this.api.post( "global/user/get_user_session", {token} , false );
            return data;
        }else
        {
            const data = await this.api.post( "global/user/get_user_session" );
            return data;
        }  
    }

    async refresh_user()
    {
        const ret = await this.get_user_profile();
        if( ret && ret.user )
        {
            this.set_user(ret.user);
        }
    }

    async get_user_profile()
    {
        const data = await this.api.post( "global/user/profile" );
        return data;
    }

    
    @observable coin_name = "金币"; 
    @observable recharge_input_coin = ""; 
    // recharge_input_icon

    // =============================
    // 云应用相关

    @observable form_app_id = ""; 
    @observable form_app_name = ""; 
    @observable form_app_docker_compose = ""; 
    @observable form_app_after_install = ""; 
    @observable form_app_link = ""; 
    @observable form_app_entrance = ""; 
    @observable form_app_custom_command = ""; 
    @observable form_instance_hours = 3; 
    @observable form_search_keyword = ""; 
    form_instance_price = 100; 
    
    async save_app()
    {
        const payload = {
            'name' : this.form_app_name,
            'docker_compose' : this.form_app_docker_compose,
            'after_install' : this.form_app_after_install,
            'link' : this.form_app_link,
            'entrance' : this.form_app_entrance,
            'custom_command' : this.form_app_custom_command,
        };
        const data = await this.api.post( "clickcloud/app/create" , payload );
        return data;
    }

    async list_app()
    {
        const data = await this.api.post( "clickcloud/app/list" , null );
        return data;
    }

    async list_public_app()
    {
        const data = await this.api.post( "clickcloud/app/public_list" , null );
        return data;
    }

    async detail_app( id )
    {
        const data = await this.api.post( "clickcloud/app/detail/"+id , {id} );
        return data;
    }

    async update_app( id )
    {
        const payload = {
            'name' : this.form_app_name,
            'docker_compose' : this.form_app_docker_compose,
            'after_install' : this.form_app_after_install,
            'link' : this.form_app_link,
            'entrance' : this.form_app_entrance,
            'custom_command' : this.form_app_custom_command,
        };
        const data = await this.api.post( "clickcloud/app/update/"+ id , payload );
        return data;
    }

    @action set_app( app )
    {
        this.form_app_id = app.id;
        this.form_app_name = app.name||"";
        this.form_app_docker_compose = app.docker_compose||"";
        this.form_app_after_install = app.after_install||"";
        this.form_app_link = app.link||"";
        this.form_app_entrance = app.entrance||"";
        this.form_app_custom_command = app.custom_command||"";
    }

    // === instance =========
    // 
    @observable instance_to_check = new Map();

    async create_instance( app_id , hours )
    {
        const data = await this.api.post( "clickcloud/instance/create/"+ app_id , {app_id, hours} );
        return data;
    }

    async list_my_instances()
    {
        const data = await this.api.post( "clickcloud/instance/list" , null );
        return data;
    }

    async detail_instance( instance_id )
    {
        const data = await this.api.post( "clickcloud/instance/detail/"+instance_id , {instance_id} );
        return data;
    }

    async destroy_instance( instance_id )
    {
        const data = await this.api.post( "clickcloud/instance/destroy/"+instance_id , {instance_id} );
        return data;
    }


}

export default new AppState();

